import { pagesPath } from '@/lib/$path'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export default function Index() {
  const router = useRouter()
  const redirectUrl = pagesPath._tenantName('tenant-name').login.$url()

  useEffect(() => {
    router.push(redirectUrl)
  }, [redirectUrl, router])

  return (
    <>
      This is Home
      <br />
      Redirecting to {redirectUrl.pathname}
    </>
  )
}
